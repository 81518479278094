import styled from 'styled-components';

export const Container = styled.div`
  padding: 20px 0px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;

  label {
    color: #43476b;
    font-size: 13px;
    font-family: 'HelveticaNeue-Bold';
  }

  input {
    height: 50px;
    border: 1px solid #d7d7d7;
    border-radius: 4px;
    margin-bottom: 15px;
  }
`;

export const SectionContainer = styled.div`
  padding: 20px 0px 0px 0px;
  display: grid;
  grid-template-columns: 1fr;

  label {
    color: #43476b;
    font-size: 13px;
    font-family: 'HelveticaNeue-Bold';
  }

  input {
    height: 50px;
    border: 1px solid #d7d7d7;
    border-radius: 4px;
    margin-bottom: 15px;
  }
`;

export const ButtonStyle = styled.button`
  width: 150px;
  height: 32px;
  padding: 7px;
  border-radius: 4px;
  font-size: 14px;
  font-family: 'HelveticaNeue-Medium';
  margin-top: 20px;
`;

export const CancelButton = styled(ButtonStyle)`
  border: 1px solid #007aff;
  background: none;
  color: #007aff;
`;

export const UpdateButton = styled(ButtonStyle)`
  border: none;
  background: #007aff;
  color: #ffffff;
`;

export const CustomLabel = styled.label`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 30px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  span {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #43476b;
    transition: 0.3s;
    border-radius: 30px;
  }

  span:before {
    position: absolute;
    content: '';
    height: 25px;
    width: 25px;
    left: 3px;
    bottom: 2.6px;
    background-color: #fff;
    border-radius: 50%;
    transition: 0.3s;
  }

  input:checked + span {
    background-color: #b62264;
  }
  input:checked + span:before {
    transform: translateX(29px);
  }
`;

export const DropLabel = styled.span`
  color: #43476b;
  font-size: 13px;
  font-family: 'HelveticaNeue-Bold';
  display: inline-block;
  margin: 8px 0;
`;
