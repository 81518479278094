import { useEffect } from 'react';
import { useRoutes } from 'react-router-dom';
import { hasUserEditorRole } from './state/slices';
import { useAppDispatch, useAppSelector } from './state/hooks';
import { appMetaDataSelector } from './state/selectors';
import { hasUserRole } from './utils/helpers';
import { AppRoutes } from './routes';
import './styles.css';

const App = ({ roles }: { roles: string[] }) => {
  const { hasRole } = useAppSelector(appMetaDataSelector);
  const routes = useRoutes(AppRoutes(hasRole));
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(hasUserEditorRole(hasUserRole(roles)));
  }, [roles]);
  return routes;
};

export default App;
