/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { H2 } from '@azu-ignite/ci-core-ui-pattern-lib';
import { useState, useMemo } from 'react';
import {
  faPlusCircle,
  faTrash,
  faEdit,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { appMetaDataSelector } from '../../state/selectors';
import {
  createTileApp,
  removeSection,
  switchEditMode,
} from '../../state/slices';
import { AppSectionsProps } from './AppSections.types';
import { Modal } from '../Modal/Modal';
import { CreateAppTile } from './CreateAppTile';
import { CreateSection } from './CreateSection';

export const AppSections = ({ sections, handleClick }: AppSectionsProps) => {
  const dispatchStore = useAppDispatch();
  const { apiResponse, hasRole, isEditMode } =
    useAppSelector(appMetaDataSelector);

  const [show, setShow] = useState<boolean>(false);
  const [showSection, setShowSection] = useState<boolean>(false);

  const [selectedSectionId, setSelectedSectionId] = useState<string>('');
  const [selectedApp, setSelectedApp] = useState<any>(null);

  const findSection = useMemo(() => {
    if (selectedSectionId) {
      return apiResponse.find(section => section?.id === selectedSectionId);
    }
    return {};
  }, [selectedSectionId]);

  const handleToggle = (status: any) => {
    dispatchStore(switchEditMode(status));
  };

  // App-tile functions
  const onClickAddButton = (id: string) => {
    setShow(true);
    setSelectedSectionId(id);
    setSelectedApp(null);
  };

  const onClickRemoveButton = (e: any, id: string, item: any) => {
    e.stopPropagation();
    setSelectedApp(null);
    const section = apiResponse.find(el => el?.id === id);
    const finalSection = {
      ...section,
      appList: section?.appList?.filter(el => el?.order !== item?.order),
    };
    // @ts-ignore
    dispatchStore(createTileApp(finalSection));
  };

  const onClickEditButton = (e: any, id: string, item: any) => {
    e.stopPropagation();
    const section = apiResponse.find(el => el?.id === id);
    const app = section?.appList?.find(el => el?.appName === item?.appName);
    setSelectedSectionId(id);
    setSelectedApp(app);
    setShow(true);
  };

  const closeModal = () => {
    setShow(false);
    setSelectedSectionId('');
    setSelectedApp(null);
  };

  // Section functions
  const onClickAddSection = () => {
    setSelectedSectionId('');
    setShowSection(true);
  };

  const onClickRemoveSection = (id: string) => {
    setSelectedSectionId('');
    // @ts-ignore
    dispatchStore(removeSection(id));
  };

  const onClickEditSection = (id: string) => {
    setSelectedSectionId(id);
    setShowSection(true);
  };

  const closeModalSection = () => {
    setShowSection(false);
    setSelectedSectionId('');
  };

  return (
    <div style={{ padding: '20px 0 0 50px' }}>
      {/* {hasRole && (
        <div style={{ display: 'flex', margin: '5px 0 25px ' }}>
          <Button
            id="brand-button"
            buttonType="primary"
            onClick={() => onClickAddSection()}
          >
            Add new section
          </Button>
        </div>
      )} */}
      {sections.map(section => (
        <div
          key={section?.id}
          style={{
            marginBottom: '25px',
            borderBottom: '0.5px solid',
            paddingBottom: '15px',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              paddingBottom: '5px',
            }}
          >
            <H2>{section?.title}</H2>
            {/* <FontAwesomeIcon
              icon={faTrash}
              color="rgb(217, 0, 0, 0.7)"
              size="lg"
              style={{
                marginLeft: '10px',
                cursor: 'pointer',
              }}
              onClick={() => onClickRemoveSection(section?.id)}
              title="Remove the section"
            /> */}
            <FontAwesomeIcon
              icon={faEdit}
              color="rgb(3, 0, 208, 0.7)"
              size="lg"
              style={{
                marginLeft: '10px',
                cursor: 'pointer',
              }}
              onClick={() => onClickEditSection(section?.id)}
              title="Edit the section"
            />
          </div>

          <div>
            {section?.items?.map(item => (
              <div
                key={item.appName}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginBottom: '5px',
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <FontAwesomeIcon
                    icon={faTrash}
                    color="rgb(217, 0, 0, 0.7)"
                    size="1x"
                    style={{
                      margin: '0px',
                      cursor: 'pointer',
                    }}
                    onClick={e => onClickRemoveButton(e, section?.id, item)}
                    title="Remove the app"
                  />
                  <FontAwesomeIcon
                    icon={faEdit}
                    color="rgb(3, 0, 208, 0.7)"
                    size="1x"
                    style={{
                      marginLeft: '5px',
                      cursor: 'pointer',
                    }}
                    onClick={e => onClickEditButton(e, section?.id, item)}
                    title="Edit the app"
                  />
                  <p style={{ marginLeft: '8px', fontSize: '17px' }}>
                    {item.appName}
                  </p>
                </div>
              </div>
            ))}
          </div>
          <FontAwesomeIcon
            icon={faPlusCircle}
            color="#006BFD"
            size="2x"
            style={{
              marginTop: '5px',
              cursor: 'pointer',
              alignSelf: 'flex-end',
            }}
            onClick={() => onClickAddButton(section?.id)}
            title="Add new app"
          />
        </div>
      ))}
      <Modal
        width="50%"
        onClose={closeModal}
        open={show}
        title={selectedApp ? 'Edit the app' : 'Add new app'}
      >
        <CreateAppTile
          closeModal={closeModal}
          selectedSection={findSection}
          selectedApp={selectedApp}
        />
      </Modal>

      <Modal
        width="50%"
        onClose={closeModalSection}
        open={showSection}
        title="Edit the section"
      >
        <CreateSection
          closeModal={closeModalSection}
          selectedSection={findSection}
          apiResponse={apiResponse}
        />
      </Modal>
    </div>
  );
};
